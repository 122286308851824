<template>
	<div>
		<b-row class="mb-2">
			<!-- Date picker -->
			<b-col cols="12" md="7">
				<b-row>
					<b-col md="5  ">
						<label for="example-input">From Date</label>
						<b-form-datepicker
							id="from-date"
							placeholder="Choose a date"
							local="en"
							v-model="search_filter.from_date"
						/>
					</b-col>
					<b-col md="5 p-0">
						<label for="example-input">To Date</label>
						<b-form-datepicker
							id="to-date"
							placeholder="Choose a date"
							local="en"
							v-model="search_filter.to_date"
						/>
					</b-col>
					<b-col md="2" class="d-flex align-items-end">
						<b-button @click="getEggStockList()" variant="success ">
							Filter
						</b-button>
					</b-col>
				</b-row>
			</b-col>
			<!-- Search -->
			<b-col
				cols="12"
				md="5"
				class="mt-2 d-flex justify-content-end align-items-center"
			>
				<div>
					<b-form-input
						type="text"
						class="form-control"
						placeholder="Search"
						@input="getEggStockList()"
						v-model.trim="search_filter.input_field"
						style="border-radius: 4px"
					/>
				</div>
				<b-button
					@click="clearFilter"
					variant="secondary
 			 ml-1"
				>
					Clear
				</b-button>
				<b-button
					:to="{
						name: 'apps-purchase-eggStock-add',
					}"
					variant="primary mr-1 ml-1"
				>
					Add
				</b-button>
				<b-button @click="excelDownload()" variant="warning">
					<feather-icon icon="DownloadIcon" size="16" />
				</b-button>
			</b-col>
		</b-row>
		<div v-if="eggStockList && eggStockList.total > 0">
			<b-table
				id="eggStockList"
				:items="eggStockList.data"
				responsive
				empty-text="No matching records found"
				:fields="fields"
				class="position-relative"
			>
				<template #cell(index)="data">
					<div class="ml-1">
						{{ eggStockList.from + data.index }}
					</div>
				</template>
				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<div class="text-nowrap">
						<router-link
							:to="{
								name: 'apps-purchase-eggStock-edit',
							}"
							class="text-secondary"
						>
							<feather-icon :id="`${data.item.id}`" icon="EditIcon" size="16" />
						</router-link>

						<feather-icon
							:id="`${data.value.id}`"
							icon="TrashIcon"
							size="16"
							class="mx-1"
						/>
					</div>
				</template>
			</b-table>
			<div class="mt-2 mb-5">
				<b-pagination
					:value="eggStockList.current_page"
					:total-rows="eggStockList.total"
					:per-page="eggStockList.per_page"
					align="end"
					@change="getEggStockList"
				>
				</b-pagination>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img
				:src="
					require('@/assets/images/svg/infographics/purchase_egg_infographic.svg')
				"
				class="mr-2"
			/>
			<h4 class="mt-2 Secondary">Egg Purchase List Empty</h4>
		</div>
	</div>
</template>

<script>
import {
	BCard,
	BImg,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BFormDatepicker,
	BTabs,
	BTab,
	BCardText,
	BFormGroup,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
	components: {
		BCard,
		BImg,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		BFormDatepicker,
		vSelect,
		BCardText,
		BTabs,
		BTab,
		BFormGroup,
	},
	data() {
		return {
			fields: [
				{ key: "index", label: "S.no" },
				{
					key: "purchase_date",
					label: "purchase date",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
        {
          key: "source",
          label: "Source",
          formatter: (value) => {
            return value.charAt(0).toUpperCase() + value.slice(1);
          },
        },
				"flock_id",
				"batch_id",
				"breed",
				"egg_qty",
				{
					key: "flock_age",
					label: "Age (in weeks)",
				},
				// "flock_age",
				"price_per_egg",
				"damaged",
				{
					key: "egg_laid_date",
					label: "egg laid date",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
				"total_cost",
				// "Actions",
			],
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
			eggStockList: {},
		};
	},
	created: function () {
		this.getEggStockList();
	},

	methods: {
		moment() {
			return moment();
		},

		getEggStockList(pageNo = 1) {
			const hatchery_id = this.$route.params.hatcheryId;
			let url = `web/hatchery/${hatchery_id}/get-egg-purchase`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.eggStockList = response.data.eggPurchase;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(
					this.search_filter.from_date
				).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format(
					"YYYY-MM-DD"
				);
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-egg-purchase`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.getSourceList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getSourceList();
				});
		},

		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getEggStockList();
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}

.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
